import { CALIDA_GROUP_COMPANY_IDS, POST_GROUP_COMPANY_IDS, TX_GROUP_COMPANY_IDS } from "../customisations/constants";
import { assignToObjectKeys } from "../utils/object";

export default Object.assign({}, commonTranslations(), commonCustomisations(), customisations());

function commonTranslations() {
    return {
        joineerContact: {
            salesEmail: "info@joineer.ch",
            salesPhone: "+41\u00A044\u00A0510\u00A038\u00A000",
        },
        addLinks:"Aggiungere link?",
        whatYouwrite: "Quello che scrivi",
        whatIsShown:"Cosa viene mostrato",
        anonymValues:"I valori numerici sono sempre registrati in forma anonima",
        pauseSurvey: "Pausa sondaggio",
        surveyPaused: "Sondaggio in pausa",
        events: {
            start: {
                title: "Inizio del sondaggio",
                description: "L'Email/SMS con l'invito a partecipare è stato inviato a tutti i partecipanti",
            },
            reminder: {
                title: "Promemoria",
                description: "Email/SMS per ricordare ai partecipanti che non hanno ancora compilato il modulo",
            },
            end: {
                title: "Fine del sondaggio",
                description: "Ultimo giorno di compilazione. I risultati saranno disponibili il giorno successivo",
            },
            today: "Oggi",
            endSurveyNow: "Finisci adesso",
            confirmation: "Conferma",
            endSurveyConfirmationMsg: "Sei sicuro di voler terminare questo sondaggio? \n Questo NON PUÒ essere annullato.",
            endSurveyScheduled: "Sondaggio terminato; si prega di concedere al sistema 15-30 minuti per l'elaborazione.",
        },
        nextDay: "il giorno lavorativo successivo",
        changeTeam: "Team di cambiamento",
        pickNewTeam:
            "Scegliete un nuovo {0} team dalla struttura organizzativa sottostante.",
        currentTeam: "Squadra attuale",
        activeSurveyMoveWarning:
            "Lo spostamento di un membro durante un sondaggio attivo cancella le sue risposte attuali. Dopo lo spostamento, sarà necessario compilare nuovamente il sondaggio per la nuova squadra.",

        startDateNextSurvey: "Data di inizio del prossimo sondaggio",
        interval: {
            label: "Intervallo di sondaggio",
            text: "Con quale frequenza si desidera effettuare il sondaggio?",
            everyw: "Ogni settimana",
            every2w: "Ogni 2 settimane",
            every3w: "Ogni 3 settimane",
            monthly: "Ogni mese",
            every6w: "Ogni 6 settimane",
            every2m: "Ogni 2 mesi",
            every3m: "Ogni 3 mesi",
            every4m: "Ogni 4 mesi",
            none: "Manuale"
        },
        rolesTitle: "Ruoli",
        roleTitle: "Ruolo",
        adminOfTeams: "Admin di",
        addCompanyAdmin: "Aggiungere un amministratore aziendale",
        tags: "Tag",
        masterfile: "Master file aziendale",
        masterfileText:
            "Suggerimenti utili e strumento di convalida per il master file: ",
        processdatafile: "Dati di Processo",
        processdatafileDescription:
            "Qui potete ottenere dati di processo interessanti per la vostra organizzazione. È inoltre possibile seguire l'evoluzione dei dati di processo nel corso di diverse indagini.",
        processDataMoreInfo: "Piu Info",

        processDataInfo: {
            title: {
                survey: "Survey",
                surveyEndDate: "Survey End Date",
                avgQuestionPerTeam: "Average Questions per Team",
                participants: "Participants",
                respondents: "Respondents",
                participationRate: "Participation Rate (%)",
                totalFF: "Total FFs",
                transparencyRate: "Transparency Rate (%)",
                FFPerRespondentPerQuestion: "FFs per Respondent per Question",
                handlingRate: "FF Handling Rate (%)",
                tasksCreated: "Tasks Created",
                closingRate: "Closing Rate (%)",
                delegationRate: "Delegation Rate (%)",
            },
            desc: {
                survey: "Ordine crescente.",
                surveyEndDate: "La data in cui è stato completato il sondaggio.",
                avgQuestionPerTeam: "Il numero medio di domande poste a ciascuna squadra nel sondaggio.",
                participants: "Il numero totale di persone invitate a partecipare al sondaggio.",
                respondents: "Il numero di persone che hanno effettivamente partecipato al sondaggio.",
                participationRate: "La percentuale di persone che hanno completato il sondaggio rispetto a quelle invitate.",
                totalFF: "Il numero totale di feedforward (FF) ricevuti nel sondaggio.",
                transparencyRate: "La percentuale di feedforward non anonimi rispetto al numero totale di feedforward.",
                FFPerRespondentPerQuestion: "Il numero medio di FF forniti per ogni domanda da una persona che ha risposto all'indagine.",
                handlingRate: "La percentuale di FF contrassegnati come 'discussi' o per i quali è stato creato un compito.",
                tasksCreated: "Il numero totale di compiti creati, compresi quelli non direttamente derivati dai FF.",
                closingRate: "La percentuale di attività contrassegnate come 'completate' rispetto al numero totale di attività create.",
                delegationRate: "La percentuale di attività delegate rispetto al numero totale di attività create.",
            },
        },

        profile: {
            activatePassword: "Attiva password",
            passwordTooltip: "Attiva l'accesso con password e disattiva l'accesso tramite link e-mail. Questo non può essere annullato.",
            passwordActiveTooltip: "La password è attiva"
        },

        myProfile: "Il mio profilo",
        teamDetails: "Dettagli del team",
        selectUser: "Selezionare l'membre",
        selectUserQR:
            "Selezionate un membre per scaricare il suo codice QR in formato PDF.",
        pleaseEnterOneFollowing: "Inserire almeno uno dei seguenti dati:",
        errorCreatingUser: "Errore nella creazione dell'membre",
        userTeamMoveWarning:
            "NB: Questo membre è attualmente in un'altra squadra. L'aggiunta di {0} a {1} lo rimuove da {2}.",
        userTeamMoveConfirm:
            "Per confermare lo spostamento, fare nuovamente clic su 'Salva'.",
        selectExistingUser: "Selezionare un membre esistente",
        addNew: "Crea nuovo",
        addExisting: "Aggiungere l'esistente",
        createMember: "Crea un nuovo membro",
        createAdmin: "Crea un nuovo amministratore",
        dialCode: "Codice di composizione",
        loadingProfile: "Profilo di caricamento",
        firstName: "Nome",
        lastName: "Cognome",
        updateName: "Aggiornare il nome",
        updateEmail: "Aggiornare l'indirizzo Email",
        updateMobile: "Aggiornamento del telefono cellulare",
        errorMobile: "Errore nell'aggiornamento del numero di cellulare",
        errorEmail: "Errore nell'aggiornamento dell'indirizzo Email",
        enterMobileText:
            "Il cellulare deve essere inserito con un codice di chiamata, ad esempio 4178322222.",
        updateLanguage: "Aggiornamento della lingua",
        deleteProfileConfirmation:
            "Siete sicuri di voler eliminare questo profilo dall'organizzazione?",
        profileDeleted: "Questo profilo è stato cancellato",
        directingToPrevious: "Indirizzamento alla pagina precedente",
        deleteProfile: "Cancellare il profilo",
        noDeleteEmailError:
            "Non è possibile cancellare l'Email poiché è necessario registrare almeno un metodo di contatto. Per eliminare l'indirizzo Email, aggiungere un numero di cellulare al profilo.",
        noDeleteMobileError:
            "Non è possibile cancellare il numero di cellulare perché deve essere registrato almeno un metodo di contatto. Si prega di aggiungere un'Email al profilo per cancellare il numero di cellulare.",
        entitiesAdministratedBy: "Squadre e strutture amministrate da {0}",
        someGuides: "Ecco alcune guide per voi!",
        "20people": "+20 persone",
        addEntityToCompany: "Aggiungi all'azienda",
        detailsPage: "Pagina dei dettagli",
        createEntityText1:
            "Per creare una nuova struttura {0} o una squadra {1}, fare clic sotto la sua posizione.",
        createEntityLvl0Text1:
            "Per creare un nuovo team {0}, fare clic sulla Azienda {1}.",
        createEntityText2:
            "Una volta creati, si può andare alla pagina dei dettagli per aggiungere amministratori/membri o continuare a creare altre team/strutture.",
        createEntityQuestion: "Cosa vorresti creare?",
        nameofNewTeam: "Nome del nuovo team",
        nameofNewStructure: "Nome della nuova struttura",
        openAll: "Apri tutto",
        closeAll: "Chiudi tutto",
        mandatoryLangTooltip:
            "La lingua delle domande obbligatorie è stabilita dall'organizzazione",
        noActiveSurveys: "Al momento non ci sono sondaggi attivi",
        teamAdmins: "Amministratori del team",
        structureAdmins: "Amministratori di struttura",
        teamMove: "Spostare il team",
        structureMove: "Struttura di spostamento",
        moveText:
            "Fare clic sulla struttura sotto la quale dovrebbe trovarsi {0}. Le opzioni non disponibili sono in grigio.",
        structureLabel: "Struttura",
        moveHere: "Spostarsi qui",
        currentLocation: "Posizione attuale",
        teamRename: "Rinominare il team",
        teamDelete: "Elimina squadra",
        structureRename: "Rinominare la struttura",
        structureDelete: "Elimina la struttura",
        deleteEntityConfirmation: "È sicuro di voler eliminare {0}?",
        structureDeleteInfo:
            "NB: Anche le squadre e le strutture sotto {0} saranno cancellate. I membri delle squadre cancellate non parteciperanno ai sondaggi e saranno elencati nella pagina 'Membri' senza una squadra assegnata.",
        teamDeleteInfo:
            "NB: Dopo la cancellazione, i membri di questa squadra saranno elencati nella pagina 'Membri' senza una squadra assegnata. I membri senza squadra non partecipano ai sondaggi.",
        newName: "Nuovo nome",
        cantMoveTopStructure:
            "Non è possibile spostare una struttura di livello superiore",
        memberRemove: "Rimuovere il membro",
        adminRemove: "Rimuovere l'amministratore",
        removeInfo:
            "PS: Una volta rimossi, i membri senza squadra possono essere trovati e riassegnati nella pagina 'Membri'.",
        adminRemoveConfirmation:
            "È sicuro di voler rimuovere {0} come amministratore da {1}?",
        memberRemoveConfirmation:
            "È sicuro di voler rimuovere {0} come membro da {1}?",
        tfaCountry: "Paese",
        tfaInvalid: "Numero non valido",
        tfaRemove0: "Si prega di rimuovere 0",
        tfaRequired: "Il numero è necessario",
        labelMobilePhone: "Cellulare",
        twoFactorTitle: "Autentificazione a due fattori",
        twoFactorIntro:
            "Al fine di visualizzare i risultati una volta che il sondaggio sarà terminato, ti chiediamo di attivare l'autenticazione a due fattori. Ti preghiamo di inserire il tuo numero di cellulare affinché in futuro ti vengano inviati i codici di autenticazione. In caso contrario, non sarà possibile accedere ai risultati.",
        twoFactorNoNr:
            "Non desidero fornire il mio numero e nemmeno avere accesso ai risultati",
        twoFactorNoNrExplanation:
            "Non fornendo il tuo numero non sarai in grado di accedere ai risultati nel dashboard dopo la fine del sondaggio. Il tuo numero sarà usato esclusivamente per l'autenticazione. Se scegli di non fornire il tuo numero ora, potrai fornirlo al tuo supervisore in un secondo momento al fine di abilitare l'autenticazione a due fattori per te.",
        twoFactorFirst:
            "Queste informazioni potranno essere visualizzate una volta inserito il codice inviato sul tuo cellulare.",
        noTeamsFoundWith: "Nessun nome di squadra trovato con {0}",
        removeFromTeam: "Rimuovere il membro",
        removeAsAdmin: "Rimuovere l'admin",
        noAdmins: "Nessun amministratore",
        noMembers: "Nessun membro",
        seeDetails: "Vedi dettagli",
        loggingOut: "Disconnessione",
        calculate: "Calcolo",
        calculateENPSavgTitle: "Calcolo dell'ENPS medio",
        calculateENPSavgLabel: "ENPS medio di:",
        calculateENPSavgSelect: "Selezionare le entità per calcolare l'ENPS medio:",
        pwProtectedMessage:
            "L'account è protetto da password. Effettuare il login con la propria password.",
        passwordError: "Errore nell'impostazione della password",
        passwordLengthError: "La password deve essere lunga almeno 7 caratteri",
        passwordMismatch: "La password non corrisponde. Riprova per favore!",
        passwordChangeSuccess: "Password modificata con successo. Accedi {0}.",
        labelNewPassword: "Nuova password",
        labelRepeatNewPassword: "Conferma nuova password",
        setNewPassword: "Imposta una nuova password di almeno 7 caratteri.",
        getNewPwLink:
            "Ottenere un nuovo link per la reimpostazione della password {0}",
        resetPw: "Reimpostare la password",
        resetPwTokenInvalid: "Link di reset password non valido",
        forgotPwTitle: "Hai dimenticato la password?",
        forgotPwText:
            "Ti invieremo un'Email con un link per reimpostare la tua password.",
        fillingOutForTeam: "Stai compilando per {0}.",
        orContinueWith: "continuare con",
        level: "Livello",
        showTodo: "Mostrare l'elenco delle cose da fare",
        hideTodo: "Nascondere l'elenco degli impegni",
        statistics: "Statistiche",
        commentTooLong: "La lunghezza massima dei commenti è di 280 caratteri",
        allDiscussedNr: "Super, tutti i {0} sono stati discussi!",
        allDiscussed: "Super, tutti i Feedforwards sono stati discussi",
        notEnoughAnswers:
            "Questa volta un numero insufficiente di membri ha completato il sondaggio",
        notEnoughAnswersTeamName:
            "Questa volta un numero insufficiente di membri del team {0} ha completato il sondaggio",
        startPrep: "Beneficiare dei contributi del team e iniziare a prepararsi",
        expandQuestionComments: "Espandi la domanda per vedere i commenti",
        pickValidTeam: "Scegliere una squadra che ha partecipato al sondaggio",
        devGeneralExplanation:
            "È la deviazione standard di tutte le valutazioni del sondaggio. Un numero minore significa che i membri del team hanno opinioni simili. Un numero più alto significa che il team dovrebbe discutere alcuni argomenti su cui le opinioni divergono.",
        avgGeneralExplanation:
            "Questa è la media di tutte le domande del sondaggio. Una media più bassa significa che c'è un maggiore potenziale di miglioramento.",
        signInWith: "Accedi con ",
        whatsNextTitle: "Cosa c'è dopo?",
        whatsNextText: "Elevare il {0}, una casella alla volta",
        viewDiscussion: "Vedi discussione",
        startDiscussion: "Avvia discussione",
        manageSurvey: "Gestire il sondaggio",
        discussionFor: "Discussione per",
        helpTitle: "Consigli e trucchi",
        helpText: "La guida per la discussione dei risultati è disponibile qui",
        finishSurvey: "Terminare il sondaggio",
        reviewResults: "Preparare la discussione",
        reviewResultsHint: "Controllate e ordinate le domande",
        scheduleMeeting: "Programmare una riunione di team",
        scheduleMeetingHint:
            "Organizzare una riunione del team di circa 45 minuti al di fuori di questa applicazione per la discussione",
        doDiscussion: "Discutere i risultati in gruppo",
        doDiscussionHint:
            "Fare clic su 'Avvia discussione' in fondo alla pagina. Discutete di Feedforward come team e create compiti se necessario.",
        resultText1:
            "Qui è possibile rivedere le domande e modificare l'ordine per la discussione.",
        resultText2:
            "Per riordinare le domande in base alla loro importanza, fare clic e trascinare l'icona {0}.",
        handBuchText: "Guida alla preparazione",
        thisTimeNoFF: "Questa volta {0} da parte del vostro team",
        bravo: "Complimenti",
        wow: "Wow",
        ffFromTeam: "dal tuo team",
        discussionCompletedPraise: "Super, avete discusso tutti i Feedforward!",
        showStats: "Mostra le statistiche",
        hideStats: "Nascondi le statistiche",
        questionLegend: "Domanda leggenda",
        orderExplanation:
            "Le domande saranno in modalità di discussione in questo ordine",
        avgExplanation:
            "I colori indicano il livello di valutazione della domanda da parte dei membri del team. Maggiore è il valore, migliore è la valutazione.",
        devExplanation:
            "I colori indicano le differenze di opinione all'interno del team. Un valore più piccolo significa che i membri del team hanno opinioni simili. Un valore più alto significa che il team necessita discutere l'argomento.",
        nrFFs: "Numero di feedforward",
        nrFFExplanation:
            "Numero di commenti lasciati dai membri del team per questa domanda",

        adminLvls: "Livello amministrativo",
        profession: "Professione",
        gender: "Genere",
        office: "Ufficio",
        location: "Posizione",
        age: "Età",
        keyboardInfoMessage:
            "È possibile cliccare su questo sondaggio utilizzando i tasti numerici della tastiera.",
        editAnswers: "Modifica risposte",
        activeSurvey: "Sondaggio attivo",
        activeSurveys: "Sondaggi attive",
        peopleWereInvitedFrom:
            "{0} persone {1} sono state invitate a compilare il sondaggio",
        fromEntity: "del {0}",
        fromWholeCompany: "dell'intera azienda",
        personFilledOut: "1 persona ha compilato il sondaggio",
        peopleFilledOut: "{0} persone hanno compilato il sondaggio",
        discussionVideoTitle: "Impulse Team discussion",
        gdpr01: "Dichiarazione di consenso",
        gdpr02: "Grazie per aver scelto Joineer. Al fine di rispettare la presente informativa sulla privacy, desideriamo informarti che, oltre al vostro nome e indirizzo Email, raccogliamo le seguenti informazioni:",
        gdpr03: "Risposte anonime quantitative per le domande sul Teambarometer.",

        gdpr04: "Commenti qualitativi opzionali in forma anonima o pubblica.",
        gdpr04pub: "Commenti qualitativi opzionali.",

        gdpr05: "Le risposte date saranno viste dai membri del tuo team, come pure dal capo reparto e dall'amministratore aziendale.",
        gdpr06: "Tutti dati vengono salvati presso dei server situati in Svizzera e non vengono trasmessi a terzi.",
        gdpr07: "Potrai accedere facilmente ai tuoi dati tramite l'applicazione.",
        gdpr08: "Si prega di valutare ciò e di accettare tali termini.",
        gdprLinkMessage: "", //For more information click
        gdprLinkTitle: "", //here
        gdprLinkUrl: "", //https://www.app.com
        gdpr09: "Per qualsiasi domanda, non esitare a contattarci su info@joineer.ch.",
        gdpr10: "Grazie per la tua fiducia!",
        gdpr11: "Cordiali saluti",
        gdpr12: "Joineer",
        gdprIgiveMyConsent: "Do il mio consenso",
        introTitle1: "Benvenuti nel Teambarometer",
        introTitle2: "La tua opinione conta - costantemente",
        introTitle3: "FeedFORWARD - nessun feedback",
        introTitle4: "Compilare - Discutere - Approfittare",
        introTitle5: "Pronti a iniziare?",
        introText1:
            "Ci rallegriamo molto della tua partecipazione - perché la tua opinione conta. Si prega di leggere attentamente le pagine successive.",
        introText2:
            "Con Joineer viene chiesta la tua opinione diverse volte all'anno. In questo modo è possibile valutare al meglio la situazione nel corso del tempo e confrontare conseguentemente i progressi.",
        introText3:
            "Non siamo una cassetta per le reclamazioni. Non criticare ciò che è andato male in passato, ma condividi come migliorare in futuro. I Feedforward sono propositivi, rivolti al futuro e mirati alla soluzione!",
        introText4:
            "Dopo il sondaggio, gli input potranno essere visualizzati nel dashboard. Quello che più conta è discutere i risultati nel team! Soltanto in questo modo é possibile misurare il successo del Teambarometer.",
        introText5L:
            "Tutto chiaro? Per qualunque domanda, non esitare a contattarci all'indirizzo info@joineer.ch. Vai alla prossima pagina ed effettua l'accesso tramite il tuo indirizzo Email e la tua password e sarai pronto a cominciare!",
        introText5:
            "Tutto chiaro? Per qualsiasi domanda, non esitare a contattarci a info@joineer.ch. Si comincia!",
        introSkip: "Salta introduzione",
        surveyToFill: "Sondaggio da compilare",
        hi: "Ciao",
        surveyStartTitle: "Ciao {0}, è un piacere averti qui.",
        startGoMessage:
            "I Feedforward ti permettono di condividere in modo positivo e orientato alla soluzione ciò che può essere migliorato in futuro!",
        startGoMessage2:
            "I risultati saranno presentati sul dashboard e discussi nei team.",
        pulseSurvey: "Sondaggio regolare",
        reference: "Nota",
        surveyEndTitle: "Bene, non manca molto...",
        surveyEndMessage:
            "Hai inviato tutti i tuoi feedforward? Clicca allora su \"Invia\". Se desideri invece cambiare qualcosa o scrivere altri commenti, puoi usare il tasto 'Indietro' per tornare alle domande.",
        surveyEndLowRatingWarning: "Hai dato un 1, il punteggio più basso, a ogni domanda. Sei sicuro di voler inviare questi risultati?",
        startSurvey: "Iniziare il sondaggio",
        questionsInSurvey: "Domande nel sondaggio",
        pickFromCatalog: "Scegli dal catalogo",
        createQuestion: "Creare una domanda",
        sendReminderTooltip: "Inviare una Email di promemoria",
        sendReminders: "Invia {0} promemoria",
        sendReminder: "Invia 1 promemoria",
        addMembers: "Aggiungere membri",
        addMember: "Aggiungi membro",
        addAdmin: "Aggiungi amministratore",
        availableSoon: "Disponibile presto!",
        remind: "Ricorda",
        remindAll: "Ricorda a tutti",
        respondedQ: "Compilato?",
        hasNotFilled: "Questo membro non ha compilato il sondaggio",
        hasFilled: "Questo membro ha compilato il sondaggio",
        membersPerPage: "Membri per pagina",
        yes: "si",
        no: "no",
        endSurvey: "Fine sondaggio",
        general: "Generali",
        surveysPaused: "I sondaggi regolari sono in pausa",
        noIntervalResults: "Nessun risultato di intervallo disponibile",
        status: "Stato",
        results: "Risultati",
        planned: "PIANIFICATO",
        ongoing: "IN CORSO",
        finished: "FINITO",
        surveyTitle: "Titolo del sondaggio",
        startDate: "Data di inizio",
        endDate: "Data di fine",
        participants: "Partecipanti",
        total: "Totale",
        totalParticipants: "Totale partecipanti",
        page: "Pagina",
        previousPage: "Pagina precedente",
        nextPage: "Pagina successiva",
        or: "o",
        none: "nessuno",
        today: "oggi",
        here: "qui",
        roles: {
            superadmin: "Amministratore aziendale",
            admin: "Team-Admin",
            member: "Membro",
        },
        greeting: "Bentornato",
        buttons: {
            settings: "Opzioni",
            saveChanges: "Salva le modifiche",
            reload: "Ricarica",
            filter: "Filtro",
            close: "Chiudi",
            surveyGo: "Iniziare",
            surveyEnd: "Invia",
            go: "Via",
            start: "Inizia",
            cancel: "Annulla",
            reset: "Resettare",
            resetAll: "Resetta tutto",
            resetSelection: "Resetta selezione",
            back: "Indietro",
            backdash: "Torna al dashboard",
            reloadComponent: "Ricarica componente",
            next: "Avanti",
            finish: "Finalizzare",
            finalise: "Finalizzare",
            save: "Salva",
            reopen: "Riaprire",
            delete: "Elimina",
            send: "Spedire",
            markDone: "Discusso",
            done: "Conclusa",
            moveUp: "Delega",
            newTask: "Nuova attività",
            expand: "Espandi tutto",
            collapse: "Minimizzare sotto",
            details: "Dettagli",
            new: "Nuovo",
            edit: "Modifica",
            add: "Aggiungere",
            addLanguage: "Aggiungere la lingua",
            addQuestion: "Aggiungi domanda",
            create: "Crea",
            askExpert: "Chiedi all'esperto",
            goTo: "Vai al",
            showAllFF: "Mostra tutti i Feedforward",
            hideAllFF: "Nascondi tutti i Feedforward",
            changeOrder: "Ordine di modifica",
            continue: "Continua",
            yesDelete: "Sì, eliminare",
            yesRemove: "Sì, rimuovi",
        },
        snackbar: {
            detailsUpdated: "Dettagli aggiornati",
            remindersSent: "I promemoria selezionati vengono inviati!",
            ffClosed: "Feedforward discusso",
            ffOpened: "Feedforward aperto",
            ffEscalated: "Feedforward inviato alla direzione per la revisione",
            taskClosed: "Attività conclusa",
            taskOpened: "Attività aperta",
            taskCreated: "Attività creata",
            taskDeleted: "Attività eliminata",
            taskDelegated: "Attività delegata",
            emailSent: "Email inviata!",
            error: "Errore",
            warning: "Avvertimento",
            success: "Successo",
            forgotPWemailSent: "Email inviata se l'account esiste",
            copiedToClipboard: "Copiato negli appunti",
            downloading: "Scaricare",
            downloadError: "Errore di download!",
            discussionReport: "Rapporto di discussione",
            questionDeleted: "La domanda è stata cancellata",
            questionAdded: "È stata aggiunta una nuova domanda",
            questionEdited: "La domanda è stata modificata",
            reordered: "Le domande sono state riordinate",
            surveyDeleted: "Il sondaggio viene cancellato",
            memberAdded: "Il membro è stato aggiunto al sondaggio",
            memberRemovedFromSurvey: "Il membro è stato rimosso dal sondaggio",
            startChanged: "La data di inizio del sondaggio è stata cambiata",
            endChanged: "La data di fine del sondaggio è stata cambiata",
            titleChanged: "Il titolo del sondaggio è stato modificato",
            memberRemoved: "Il membro è stato rimosso",
            adminRemoved: "L'amministratore è stato rimosso",
            teamCreated: "Il team è stato creato",
            structureCreated: "La struttura è stata creata",
            teamDeleted: "Il team è stata cancellata",
            structureDeleted: "La struttura è stata eliminata",
            structureRenamed: "La struttura è stata rinominata",
            teamRenamed: "Il team è stato rinominato",
            teamMoved: "Il team è stata spostata",
            structureMoved: "La struttura è stata spostata",
            newMemberCreated: "È stato creato un nuovo membro",
            newAdminCreated: "È stato aggiunto un nuovo amministratore",
            surveyEnded: "La fine del sondaggio è stata programmata e sarà completata nei prossimi 15 minuti.",
            surveyUpdated: "Il sondaggio è stato aggiornato",
            useImportTool: "Per creare strutture di livello inferiore, utilizza lo strumento di importazione Excel o contatta il supporto Joineer",
            feedbackSubmitted: "Il tuo feedback ci aiuta a migliorare. Grazie!",
        },
        tooltips: {
            editTeamName: "Modificare il nome",
            deleteTranslations: "Cancellare le traduzioni in {lang}",
            discussed: "Contrassegna Feedforward come discusso",
            createTask: "Crea attività dal Feedforward",
            createFreeTask:
                "Crea un'attività non correlata a nessun Feedforward specifico",
            closeTask: "Contrassegna l'attività come conclusa",
            moveUp: "Delega l'attività agli amministratori",
            deleteTask: "Elimina attività",
            reopenFF: "Riapri Feedforward",
            addComment: "Aggiungi un commento",
            discussedDisabled:
                '"Contrassegna Feedforward come discusso" disabilitato in modalità anteprima',
            disabled: "Disabilitato in modalità anteprima",
            hideFF: 'Contrassegna Feedforward come "gestito in modalità incontro individuale" e non mostrarlo in modalità discussione',
            showFF: "Mostra Feedforward in modalità discussione",
            required: "Necessario",
            pressEnter: "Per salvare premi invio",
            surveyCanNotBeDeleted:
                "Solo i sondaggi pianificati possono essere cancellati.",
        },
        nav: {
            structure: "Struttura di organizzazione",
            companyAdmins: "Amministratori aziendale",
            calendar: "Calendario del sondaggio",
            dashboard: "Dashboard",
            discussion: "Modalità di discussione",
            reports: "Rapporti",
            delegatedTasks: "Compiti delegati",
            teamTasks: "Compiti di squadra",
            team: "Team",
            members: "Membri",
            interval: "Lasso di tempo",
            adhoc: "Adhoc sondaggi",
            questions: "Domande",
            profile: "Profilo",
            logout: "Disconnettersi",
            intercomChat: "Chatta con noi!",
            qrCodes: "Lettere QR",
            tfa: "Autenticazione a due fattori",
            ffanalysis: "FeedForward Analysis",
            feedForwards: "FeedForwards",
            emailTemplates: "E-Mails"
        },
        selectSurvey: {
            customised: "Personalizzato",
            choose: "Seleziona un sondaggio da rivedere in modalità Discussione",
            now: "Adesso",
            team: "Team",
            selectTeam: "Seleziona team",
            placeholder: "Seleziona sondaggio",
            goDiscussion: "Vai alla modalità di discussione",
            selectModeLabel: "Seleziona la modalità di visualizzazione",
            preview: "Modalità anteprima",
            prepare: "Preparare",
            previewBody:
                "Questa è un'anteprima della modalità di discussione utilizzata in vista della vera riunione con il team. È possibile nascondere alcuni Feedforward al fine di trattarli in un incontro individuale.",
            present: "Presentare",
            presentBody:
                "Presentazione e discussione dei risultati all'interno del team.{0} I Feedforward sono trasformabili in attività di team.",
            noTeam: "Questo team non esiste",
            noSurvey: "Non è ancora stato completato nessun sondaggio",
            comeBack:
                "Torna a discutere i risultati una volta completato il tuo primo sondaggio!",
            handbuchLink:
                "Suggerimenti e trucchi per una discussione di successo dei risultati nel team possono essere trovati {here}",
            questionOrder: "Ordine di discussione",
            original: "come nel sondaggio",
            overall: {
                average: "Media generale",
                deviation: "Deviazione standard generale",
            },
        },
        discussionMode: {
            question: "Domanda",
            survey: "Sondaggio",
            anonym: "Anonimo",
            noFF: 'Nessun Feedforward, clica "Avanti',
            noDiscussed:
                "I Feedforward contrassegnati come discussi {0} verranno visualizzati qui",
            noTasks:
                "I Feedforward trasformati in attività {0} verranno visualizzati qui",
            createTask: "Crea attività da Feedforward",
            feedforwards: "Feedforwards",
            discussed: "Discusso",
            tasks: "Attività",
            moveUpTo: "Delega l'attività a:",
            highlight: "Delegare Feedforward",
            describe: "Indica il motivo della delega",
            selectLabel: "All'attenzione di:",
            unable: "Non è possibile risolverlo all'interno del team?",
            hiddenFFs:
                'I Feedforward sottostanti verranno nascosti durante la "presentazione in modalità discussione". Il numero di Feedforward nascosti sarà comunque visibile.',
            nrhiddenFFs: 'Numero di Feedforward risolti in "incontro individuale"',
        },
        achievements: {
            title: "Traguardi",
            intro: "Ecco i traguardi già raggiunti",
        },
        tasks: {
            taskPage: "Pagina dei compiti",
            taskBannerText: "Compiti creati dalla discussione",
            createTitle: "Crea attività",
            taskDisabled: "In modalità anteprima non si possono creare attività",
            titlePlaceholder: "Titolo dell'attività",
            title: "Titolo",
            commentPlaceholder: "Commento facoltativo",
            origin: "Proveniente da",
            addComment: "Aggiungi un commento",
            comments: "Commenti",
            noComments: "Non ci sono commenti",
            dueDate: "Scadenza",
            dueBy: "Dovuto a",
            setDueDate: "Fissa una data di scadenza",
            assign: "Assegna attività a",
            assignedTo: "Assegnato a",
            other: "altro",
            enterTitle: "Inserisci il titolo dell'attività",
            myTasks: "Le mie attività",
            teamTasks: "Attività del team",
            openTasks: "Attività aperte",
            completedTasks: "Attività concluse",
            deleteTitle: "Elimina attività",
            deleteBody:
                "Sei sicuro di voler eliminare definitivamente questa attività?",
            noTasks: "Nessuna attività aperta",
            delegatedExplanation:
                "Elenco di Feedforward o compiti di squadra che sono stati delegati all'attenzione degli amministratori.",
            noCompletedTasks: "Ancora nessuna attività conclusa",
            noDelegatedTasks: "Nessun compito delegato ancora",
            assigneeRequiredMessage: "Seleziona un membro a cui assegnare l'incarico",
            sendEmail: "Invia il riepilogo dell'attività tramite Email",
            calendarEntry: "Con inserimento del calendario",
            calendarEntryNo: "Senza inserimento nel calendario",
            commentDeletedBy: "Commento eliminato da {updatedBy}",
            deleteComment: "Eliminare questo commento?",
            updateComment: "Salvare le modifiche",
            edited: "modificato",
            editComment: "Modifica commento",
            afterDelete:
                "Una volta cancellato, sarà visibile nella cronologia dei commenti delle attività",
        },
        completion: {
            title: "Molto bene!",
            tasksCreated: "Attività create",
            ffDiscussed: "Feedforwards discussi",
            go: "Vai al taskboard",
        },
        intro: {
            title: "Seleziona l'ordine delle domande",
            intro: "In base ai risultati, raccomandiamo di discutere i Feedforwards nel seguente ordine. Trascina {0} sull'elemento per cambiare l'ordine",
            start: "Inizia la discussione",
        },
        login: {
            title: "Accesso",
            pw: "Password",
            forgot: "Hai dimenticato la password?",
            withLink: " con collegamento diretto",
            withPassword: " con password",
            checkEmail:
                "Se disponete di un account con questo indirizzo Email, vi verrà inviato il link di accesso.",
            incorrectEmail: "Email errata o mancante",
            labelSendLink: "Invia link",
            labelLinkSent: "Richiesta riuscita",

            enterNameAndPassword:
                "Inserisci ora il tuo indirizzo Email e la tua password.",
            enterEmail:
                "Inserisci il tuo indirizzo Email per recuperare un link di accesso diretto.",
            resetSent: "Ti è stata inviata l'Email per impostare la password.",
            getLink: "Recupera il link per il login",
        },
        admin: {
            title: "Admin Board",
            allTeams: "Tutti i team",
            allSurveys: "Tutti i sondaggi",
            selectedTeams: "Team selezionati",
            deselectAll: "Deseleziona tutto",
            selectAll: "Seleziona tutto",
            created: "Creato il",
            markDone: "Contrassegna come risolto",
            done: "Risolto",
            latestComment: "Ultimo commento",
            lessComments: "meno commenti...",
            moreComments: "più commenti ...",
            empty: "Nessuna attività conclusa per il momento",
            deleteConfirmation: "Sei sicuro di volerlo eliminare definitivamente?",
        },
        stats: {
            decreased: "diminuite",
            increased: "aumentato",
            avg1: "è la valutazione media del team per questa domanda. Una media bassa indica che vi è un margine di miglioramento.",
            avg2: "Rispetto al sondaggio precedente, il risultato è {0} del {1}%.",
            dev1: "è la deviazione standard delle risposte per questa domanda. Una deviazione standard bassa indica che il team ha opinioni simili. Più alto è il numero, più è probabile che ci sia del potenziale di discussione all'interno del team.",
            dev2: "Rispetto al sondaggio precedente le differenze sono {0} di {1}.",
            graphIntro:
                "Il grafico sottostante può aiutare a interpretare i risultati",
            devGraph1Label: "Unanime",
            devGraph1Explanation: "Un cuore solo, un'anima sola.",
            devGraph2Label: "Differente",
            devGraph2Explanation: "Deviazioni nella norma.",
            devGraph3Label: "Necessità di discutere",
            devGraph3Explanation: "La situazione viene valutata in modo diverso.",
            avgGraph1Label: "Miglioramento",
            avgGraph1Explanation: "Esiste un notevole potenziale di miglioramento.",
            avgGraph2Label: "Neutrale",
            avgGraph2Explanation: "Sufficiente. Migliorare in modo mirato.",
            avgGraph3Label: "Continuare così",
            avgGraph3Explanation: "Fare e ricevere apprezzamenti.",
            histogramTitle: "Distribuzione delle risposte",
            histogramNr: "Numero di persone",
            histogramGrade: "Grado",
            histogramAnswered: " persone hanno risposto ",
        },
        report: {
            title: "Rapporto Teambarometer",
            selectLabel: "Visualizza rapporto di",
            company: "Azienda",
            summary: "Riepilogo",
            all: "Tutti",
            pleaseWait: "Caricamento del rapporto... attendere prego",
            noData: "Nessun rapporto disponibile",
            searchLabel: "Cerca",
            name: "Nome",
            totalFF: "Totale dei Feedforwards",
            noFFsInSurvey:
                "Nessun feedforward è stato lasciato in questo sondaggio",
            closedFF: "Feedforwards discussi",
            totalTasks: "Attività totali",
            taskedFF: "Attività generate dai Feedforwards",
            delegatedTasks: "Attività delegate",
            closedTasks: "Attività concluse",
            wholeCompany: "Tutta l'azienda",
            advancedTeamCompare: "Confronto di squadra",
            discussionSummary: "Riassunto della discussione",
            discussionSummaryText:
                "Panoramica delle discussioni sui risultati tenute all'interno dell'azienda. Controlla in quali team gli input sono già stati discussi o implementati.",
            download: "Scaricare",
            downloadSummary: "Scaricare il riassunto (.pptx)",
            preparingFile: "Preparazione del file...",
            presentation: "Presentazione",
            matrixTitle: "Matrix heatmap",
            matrixText:
                "Panoramica a colori delle risposte per team e per domanda.",
            matrixTextMinFill:
                "I team senza abbastanza risposte sono escluse dal rapporto.",
            selectReport: "Seleziona il rapporto",
            displayError:
                "La tabella non può essere generata. Prova invece a scaricare i risultati.",
            averages: "Media",
            deviations: "Deviazione standard",
            enps: "ENPS",
            enpsScore: "Valutazione ENPS",
            answerNr: "Numero di risposte",
            surveyEnded: "Sondaggio terminato ",
            "1dayAgo": "{0} giorno fa",
            xdaysAgo: "{0} giorni fa",
            pastSurveys: "Storia del sondaggio",
            endedAt: "Finito alle",
            team: "Team",
            teams: "Squadre",
            companyGoal: "Obiettivo dell'azienda",
            handleFeedforwards: "gestire tutti i Feedforward",
            howManyHandled: "{0} Feedforwards su {1} sono stati gestiti",
            handlingRate: "Tasso di gestione",
            FFHandleTitleCompanyAdmin: "A che punto sono i tuoi team?",
            FFHandleTextCompanyAdmin:
                "Per rendere il sondaggio un successo di squadra, è importante gestire tutti i Feedforward subito dopo il sondaggio.",
            "2weeksafter":
                "2 settimane dopo il sondaggio, la tabella è colorata in base al tasso di gestione dei feedforward. I colori presentano la % di Feedforward che vengono discussi o assegnati all'interno del team.",
        },
        genericApiErrorMessage: "L’operazione non è stata possibile",
        errors: {
            selectUser: "Selezionare prima un membre",
            submit: "Errore nell'invio del sondaggio",
            invalidFormat: "Formato non valido",
            notPossibleRunningSurvey:
                "Non è possibile durante un sondaggio in corso",
            oops: "Oops!",
            "error-4000":
                "Non ci sono abbastanza dati disponibili per questa indagine e i tag selezionati",
            noData: "Nessun dato",
            noDataYet: "Nessun dato disponibile",
            notEnoughData:
                "Non ci sono abbastanza dati disponibili per questa indagine e i tag selezionati",
            notEnoughResponseData: "Non ci sono abbastanza dati di risposta",
            displayError: "La tabella non può essere generata.",
            generalError: "Qualcosa è andato storto",
            featureNotEnabled:
                "Questa funzione non è abilitata sul tuo account. Contattateci all'indirizzo info@joineer.ch o +41\u00A044\u00A0510\u00A038\u00A000 per maggiori informazioni.",
            featureNotAllowed:
                "Questa funzione non è abilitata per il tuo ruolo utente",
            missingFields: "Si prega di compilare tutti i campi",
            noPermission: "Nessun permesso per visualizzare questa pagina",
            incorrectUrl: "URL non corretto",
            incorrectEmail: "Formato Email non corretto",
            login400: "Email o altre informazioni di accesso mancanti",
            login401: "La password o il nome utente non sono corretti",
            login401token: "Il token di accesso non è corretto",
            login403:
                "L'account è protetto da password. Effettuare il login con la propria password.",
        },
        dashboard: {
            participation: "Partecipazione",
            participants: "Partecipanti",
            feedforwards: "Feedforwards",
            pariticantsTooltip: "{0} su {1} partecipanti hanno inviato le loro risposte a questo sondaggio",
            feedforwadTooltip: "Sono stati inviati {0} feedforward in questo sondaggio",
            showCompanyHint: "Mostra anche i dati a livello aziendale",
            company: "Azienda",
            companyLine: "Azienda (linea del grafico)",
            companyStatistics: "Azienda (statistiche)",
            allYourTeams: "Tutti i tuoi team",
            allYourTeamsLine: "Tutti i tuoi team (linea del grafico)",
            allYourTeamsStatistics: "Tutti i tuoi team (statistiche)",
            surveySuccessfullySubmitted: "Sondaggio inviato con successo",
            surveyFilledOutByOutOf: "{0} su {1}",
            surveyResults: "Risultati del sondaggio",
            summary: "Riassunto",
            users: "Utenti",
            resultsFor: "Risultati per",
            wholeCompany: "Tutta l'azienda",
            ownTeam: "Proprio team",
            ownTeams: "Proprie squadre",
            team: "Team",
            inTeam: "in {0}",
            adminOf: "admin: {0}",
            noTagsMessage: "Per eseguire il filtraggio basato sui tag, aggiungi tag agli utenti",
            compareWith: "Confronta con",
            compareExplanation: "Confrontare i risultati con i seguenti:",
            questionOverview: "Panoramica delle domande",
            average: "Media",
            deviation: "Deviazioni standard",
            companyHistory: "Media dell'azienda nei sondaggi precedenti",
            compareHistory: "Media dei sondaggi precedenti",
            filterExplanation: "Filtra i risultati del sondaggio per tag:",
            "dev-good": "D'accordo",
            "dev-normal": "Diverso",
            "dev-bad": "Molto diverso",
            distributionAnswers: "Distribuzione delle risposte",
            surveyDate: "Data del sondaggio",
            companyAvg: "Media aziendale",
            positive: "positivo",
            negative: "negativo",
            noFFs: "Nessun Feedforward",
            perMember: "per membro",
            otherTags: "Altri tag",

            waitingSurvey: "In attesa dell'inizio del primo sondaggio al ",
            waitingTeam: "Attendi che il tuo team finisca di compilare il sondaggio",
            surveyStatus: "Stato del sondaggio: ",
            meanWhile: "Nel frattempo puoi:",
            here: "qui",
            addMembers: "Aggiungi altri utenti {0}",
            editQ: "Modifica domanda {0}",
            editInterval: "Modifica intervallo {0}",

            scheduleSurvey: "Pianifica un sondaggio",
            takeSurvey: "Partecipa al sondaggio",
            editYourSurveyAnswersUntil: "Modifica le risposte (fino a {0})",
            surveyInProgressTitle: "Il sodaggio è in corso",
            surveyStatusLoading: "caricamento ...",
            surveyStatusRunning: "In corso",
            surveyStatusFilled: "In corso",
            surveyStatusScheduled: "Pianificato",
            surveyStatusFirstScheduled: "Primo sondaggio pianificato",
            surveyStatusWaiting: "In attesa",
            surveyDetailsLoading: "",
            surveyDetailsRunning: "Non hai ancora compilato il sondaggio. Condividi la tua opinione entro il {0}!",
            surveyDetailsRunningNonMember: "Il sondaggio attuale terminerà il giorno {0}!",
            surveyDetailsFilled: "Puoi aggiornare le tue risposte al sondaggio fino al {0}.",
            surveyDetailsFilledNonMember: "Il sondaggio attuale terminerà il giorno {0}!",
            surveyDetailsScheduled: "Il sondaggio inizia il {0}",
            surveyDetailsFirstScheduled: "In attesa del primo sondaggio il {0}",
            surveyDetailsWaiting: "Pianifica un sondaggio ...",
            surveyEditTitle: "Il tuo contributo è prezioso",
        },
        teamMemberDisplay: {
            title: "Membri che hanno partecipato al sondaggio",
            noResponse: "Nessun membro di questo team ha risposto al sondaggio"
        },
        questionEditor: {
            questionsTitle: "Domande",
            questionsIntro:
                "Le domande con sfondo giallo {0} sono domande che vengono poste all'intera azienda o a un'unità sovraordinata. Le domande con sfondo blu {1} vengono poste solo all'unità selezionata.",
            questionsIntroType:
                "Domande dello stesso tipo mostrano un'icona di collegamento {0} passandoci sopra il mouse. È possibile confrontare le statistiche dei sondaggi per queste domande.",
            survey: "Sondaggio",
            company: "Azienda",
            companyQ:
                "Visualizzare e modificare domande relative all'intera azienda presenti nel sondaggio di ciascun team",
            teamSelectLabel: "Visualizzazione delle domande per:",
            currentSurvey: "Sondaggio in corso",
            pastSurvey: "Sondaggio precedente",
            nextSurvey: "Sondaggio successivo",
            noEdit: "I sondaggi precedenti non possono più essere modificati ma rimangono a disposizione come riferimento.",
            adminQuestionNoEdit:
                "La domanda è stabilita dall' azienda o dal reparto. È possibile modificarla esclusivamente da un amministratore di reparto o da un amministratore aziendale.",
            notAsked: "Non chiesto",
            noQuestionEdit:
                "Questa domanda non può essere modificata, poiché appartiene a una categoria superiore.",
            noQuestionEditView:
                "È possibile apportare modifiche  esclusivamente a livello di reparto o a livello aziendale",
            addError: "La domanda non può essere aggiunta.",
            editError: "La domanda non può essere modificata.",
            deleteError: "La domanda non può essere eliminata.",
            nextError: "Il prossimo sondaggio non é ancora disponibile",
            catalogIntro:
                "Lasciatevi ispirare dal nostro catalogo di domande. Aprite le categorie e selezionate una domanda facendo clic su di essa. Una volta assegnata una domanda al sondaggio, è possibile modificare l'illustrazione e il testo utilizzando l'icona di modifica {0}.",
            questions: {
                question: "Domanda",
                type: "Genere",
                illustration: "Illustrazione",
                topic: "Argomento",
                preview: "Anteprima",
                dragOrder:
                    "Trascina {0} e rilascia per modificare l'ordine delle domande.",
                addQuestion: {
                    en: "Inglese",
                    de: "Tedesco",
                    it: "Italiano",
                    fr: "Francese",
                    pl: "Polacco",
                    sk: "Slovacco",
                    hu: "Ungherese",
                },
                mandatory: "Lingua obbligatoria",
                language: "Lingua",
                chooseLang: "Seleziona lingua",
                example: "Esempio:",
                exampleQ: "Sei soddisfatto del risultato del tuo lavoro?",
                exampleT: "Attività lavorativa",
            },
            addQuestion: {
                addTitle: "Aggiungi domanda al sondaggio",
                newTitle: "Nuovo",
                catalogTitle: "Catalogo",
                reuseTitle: "Riutilizzare",
                new: "Crea la tua domanda e personalizzala a seconda delle esigenze del tuo team",
                catalog: "Seleziona una nuova domanda dal catalogo",
                reuse: "Aggiungi al sondaggio una domanda già usata in precedenza",
                noReuse:
                    "La cronologia delle domande non é attualmente disponibile",
                newType:
                    "Crea una nuova domanda con cui misurare la percezione del tuo team riguardante un argomento specifico.",
                newLinkedType:
                    "Crea una nuova domanda collegata a una domanda utilizzata in precedenza",
                linkedTooltip1:
                    "Questa opzione è indicata per definire domande che misurano la stessa cosa, ma che sono formulate in modo diverso o che sono poste da una nuova angolazione.",
                linkedTooltip2:
                    "Il collegamento di domande simili consente valutazioni pratiche e visive tra i sondaggi, come indicato nella figura sottostante",
                linkTitle: "Collega la domanda",
                enps: "ENPS",
                textTitle: "Testo della domanda",
                copyNotice:
                    "I testi delle domanda selezionata sono stati copiati nella parte sottostante al fine di aiutarvi.",
                noLangContact:
                    "Se desideri aggiungere una lingua non elencata qui, contattaci {0} e saremo felici di aggiungerla manualmente per te.",
                contact: "contatto",
                langsUsed:
                    "Tutte le lingue predefinite {0} sono disponibili. Se desideri aggiungere altre lingue, non esitare a {1} e saremo lieti di aggiungerle per te.",
            },
            search: {
                mayAdd: "È possibile aggiungere al sondaggio una domanda già utilizzata precedentemente.",
                searchLabel: "Cerca tra le domande esistenti",
                willBeLinked:
                    "Le statistiche per la domanda che crei ora verranno confrontate con la domanda seguente:",
                askedBefore: "Questa domanda è stata posta in precedenza in:",
                allPreviousQuestionsLinked: "Tutte le domande precedenti sono già state collegate o aggiunte. Puoi aggiungere altre domande dal catalogo o crearne di nuove.",
            },
            dialogs: {
                editQ: "Modifica domanda",
                editExplanation:
                    "Modifica la formulazione, l'illustrazione o aggiungi nuove lingue",
                deleteExplanation: "Elimina la domanda dal sondaggio",
                deleteTitle: "Elimina domanda",
                deleteConfirmation: "Vuoi eliminare questa domanda?",
                deleteLong:
                    "Se elimini la domanda, essa non sarà più visibile nei sondaggi futuri, ma potrai ancora vederla nelle statistiche precedenti.",
            },
        },
        forms: {
            search: "Cerca",
        },
        adhoc: {
            description1: "Sondaggi una tantum",
            surveyDetails: "Dettagli del sondaggio",
            detailsDescription: "Imposta titolo e date",
            participantsDescription: "Scegliere chi compila il sondaggio",
            participantsMinFill1:
                "Almeno 1 membro dovrebbe compilare il sondaggio perché i risultati possano essere calcolati",
            participantsMinFillLarger:
                "Almeno {0} membri dovrebbero compilare il sondaggio per calcolare i risultati",
            notEnoughMembers:
                "Non ci sono abbastanza membri nel sondaggio! Minimo: {0}",
            questionsDescription: "Scegliere le domande per il sondaggio",
            review: "Revisione",
            reviewDescription: "Riassunto del sondaggio",
            activeFilters: "Filtri attivi",
            filterByEntities: "Filtra per entità",
            active: "attivo",
            filterByTags: "Filtra per tag",
            reset: "Azzera tutti i filtri e cancella i partecipanti aggiunti al sondaggio",
            removeFromSurvey: "Rimuovi il partecipante dal sondaggio",
            usersAddedToSurvey: " partecipanti aggiunti al sondaggio",
            questionsAdded: "Domande aggiunte al sondaggio",
            surveyPeriod: "Periodo del sondaggio",
            editParticipants:
                "Torna indietro e modifica i partecipanti al sondaggio",
            editPeriod: "Torna indietro e modifica il periodo del sondaggio",
            editQuestions: "Torna indietro e modifica le domande del sondaggio",
            editTitle: "Torna indietro e modifica il titolo del sondaggio",
            datesWrongError:
                "Ops! Assicurati che la data di fine sia successiva alla data di inizio",
            surveySuccess: "Sondaggio creato con successo",
            surveyDeleteConfirmation:
                "Sei sicuro di voler cancellare il sondaggio ",
            noMembersFound:
                "Nessun membro trovato con i filtri correnti. Prova a rimuovere alcuni dei filtri attivi per ampliare la selezione o {0} i filtri",
            manageSurvey: "Manage survey",
            endNow15min:
                "Questa azione fermerà il sondaggio in corso. Le modifiche avranno effetto tra 15 minuti.",
            endNowConfirmation: "Sei sicuro di voler terminare questo sondaggio?",
        },
        askExpert: {
            text: "Situazione difficile nella squadra? Non sei sicuro di come discutere i risultati nel team? {0} sarà lieto di sostenervi nelle domande difficili.",
            subject: "Oggetto",
            from: "Da:",
            to: "A:",
            messagePlaceholder: "Ho la seguente domanda...",
            askQuestions: "Se avete domande specifiche, ",
            yourExpertHere: "chiedi al tuo esperto qui",
        },
        survey: {
            opener: "Je pense que dans le futur, il serait bon que",
            publicComment: "Commento pubblico",
            anonComment: "Commento anonimo",
            infoNumericalValueResponse:
                "Valore numerico: Registrato senza il tuo nome",
            infoAnonComment:
                "Commento anonimo: Registrato senza il tuo nome",
            infoPublicComment:
                "Commento pubblico: Il tuo nome è visibile al tuo team e agli amministratori",
            labelNoAnswerGiven: "Nessuna risposta",
            labelCantAnswer: "Non posso rispondere a questa domanda",
            infoFeedforward: "Cos'è un buon Feedforward e come si scrive? Guarda il video",
            teamQuestion: "Domanda di squadra",
            whatIsAFeedforward: "Cos'è il feedforward?",
            errors: {
                surveyFinished: "Questo sondaggio è già terminato, puoi visualizzare i risultati sulla dashboard",
                getSurveyQuestion: "Si è verificato un errore durante il recupero di questa domanda",
                surveySettings: "Si è verificato un errore durante il recupero delle impostazioni",
                saveAnswer: "Si è verificato un errore durante il salvataggio di questa risposta",
            }
        },
        remindOrSuspend: {
            resultsCannotBePrepared: "Coinvolgi i tuoi membri del team",
            of: "dei",
            teamMembersCompleted: "membri del team hanno completato il sondaggio",
            suspendOrRemind: "Ricordare o sospendere",
            remind: "Ricordare",
            suspend: "Sospendere",
            membersWithIncompleteSurvey: "Membri del team",
            notifyRemindersSentAndSuspended: "I membri del team hanno ricevuto un richiamo oppure sono stati sospesi!",
            notifyRemindersSent: "I membri del team hanno ricevuto un richiamo!",
            notifySuspended: "I membri del team sono stati sospesi!",
            errorSendingReminders: "Impossibile completare la sospensione o il promemoria",
            selectAll: "Seleziona tutto",
            searchMembers: "Cerca membri o team...",
            labelNoMember: "Nessun membro trovato",
            all: "Tutte",
            nobodyToSuspendOrRemind: "Tutti gli membri hanno già compilato il sondaggio",
            membersLeftToFillCurrentSurvey: "membro rimasto per compilare il sondaggio in corso | membri rimasti per compilare il sondaggio in corso",
            membersHaveFilledOutTheCurrentSurvey: "membro ha compilato l'attuale sondaggio | i membri hanno compilato l'attuale sondaggio",
            surveyWillEndOn: "Il sondaggio terminerà il {0}",
            listOfMembersToRemindDidNotLoad: "Impossibile recuperare l'elenco degli membri da ricordare o sospendere",
            reloadRemindMemberList: "Ricarica l'elenco dei promemoria",
        },
        tfa: {
            title: "Autenticazione a due fattori",
            description: "Per la tua sicurezza, abbiamo inviato un codice di conferma a {0}. Inserisci il codice qui sotto per accedere alla tua dashboard.",
            invalidCode: "Codice non valido. Riprova.",
            smsSent: "SMS con il codice inviato a {0}.",
            notReceived: "Non hai ricevuto il codice?",
            resend: "Rinvia",
            resendIn: "Rinvia tra {0} secondi",
        },
        videoModal: {
            gotIt: "Capito"
        },
        feedforwards: {
            overallSummary: "Intuizioni generali",
            summary: "Intuizioni",
            aiTooltip: "Scritto da Joineer AI",
            teamQuestions: "Domande di squadra",
            download: "Scaricare",
            summaryObservations: "Commenti",
            summaryObservationsPlaceholder: "Si prega di fornire i propri commenti",
            searchFeedforwards: "Cerca feedforwards",
            noFeedforwardsFoundWith: "Nessun feedforward trovato con {0}, prova un termine di ricerca diverso o restringi la ricerca",
            copyToClipboard: "Copia gli intuizioni negli appunti",
            copyToClipboardSuccessMsg: "Intuizioni copiate negli appunti",
            tags: {
                incomplete: "incompleto",
                inaccurate: "impreciso",
                offensive: "offensivo",
                disagree: "disaccordo",
                badStyling: "cattivo stile",
                agree: "concordare",
                complete: "completo",
                correct: "corretto",
                wellWritten: "ben scritto",
                easyToRead: "facile da leggere",
                other: "altro",
            }
        },
        emailTemplateFields: {
            someLangsAreEmpty: "Alcune traduzioni dei modelli non hanno contenuto",
            translations: "Traduzioni",
            hide: "Nascondi",
            show: "Mostra",
            variables: "Variabili",
            subject: "Oggetto:",
            title: "Titolo:",
            greeting: "Saluto:",
            paragraph1: "Paragrafo 1:",
            buttonText: "Testo del pulsante",
            paragraph2: "Paragrafo 2:",
            errorSavingTemplate: "Errore durante il salvataggio del modello",
            removeCustomisation: "Rimuovi personalizzazione",
            removeCustomisedTemplateHint: "Questo eliminerà questo modello personalizzato per '{0}' e al suo posto verrà inviato quello predefinito",
            cancel: "Annulla",
            save: "Salva",
            deleteTemplateQuestion: "Vuoi eliminare questo modello di e-mail?",
            deleteTemplateQuestionExplanation: "Dopo l'eliminazione verranno utilizzati i modelli di e-mail predefiniti.",
            yesDelete: "Sì, elimina",
            emailSubjectLine: "Riga dell'oggetto dell'e-mail"
        },
        companyTemplates: {
            emailTemplate: "Modello di e-mail",
            emailTemplates: "Modelli e-mail",
            templateEdited: "Modello modificato",
            badTemplateFormat: "Assicurati che vengano utilizzate solo variabili valide: le variabili sono elencate sopra e iniziano tutte con {{ e finiscono con }}. \nDetails: ",
            noTemplateSelected: "Nessun modello selezionato",
            sendOnlyForEmails: "L'anteprima può essere inviata solo per i modelli di e-mail",
            previewEmailSent: "E-mail inviata a {0} (controlla lo spam), in lingua {1}",
            templateDeleted: "Modello eliminato",
            templateCreated: "Modello creato",
            SURVEY_PREPARATION: "preparazione del sondaggio",
            SURVEY_START: "inizio del sondaggio",
            SURVEY_REMINDER: "promemoria del sondaggio",
            SURVEY_END: "fine del sondaggio",
            DM_REMINDER: "promemoria DM",
            ADHOC_START: "inizio adhoc",
            ADHOC_REMINDER: "promemoria adhoc",
            ADHOC_END: "fine adhoc",
            EMAIL: "e-mail",
            SMS: "sms",
            noAdhocUpsellPrompt: "Funzione Adhoc non attivata. Si prega di contattare Joineer per ulteriori informazioni a {0} o {1}",
            goToTemplatePage: "Vai alla pagina del modello",
            details: "dettagli",
            preview: "anteprima",
            generalEMailSettings: "Impostazioni generali delle e-mail",
            previewSendHint: "Invia un test {0} al TUO {1}",
            email: "e-mail",
            mobile: "cellulare",
            saveAndSendTest: "Salva & invia test",
            sendTest: "Invia test",
            errorSendingTemplate: "Errore durante l'invio del modello",
            error: "Errore",
            channel: "Canale",
            event: "Evento",
            details: "Dettagli",
            customise: "Personalizza"
        },
        companyConfig: {
            all: "tutti",
            companyConfigNote: "Queste impostazioni si applicano ai {0} modelli di e-mail per questa azienda. Per regolare o modificare queste impostazioni, si prega di contattare {1} o {2}",
            backgroundColor: "Colore di sfondo:",
            showBanner: "Mostra banner:",
            showBannerWithAColorBackground: "Mostra banner con sfondo colorato",
            bannerColor: "Colore del banner:",
            showLogo: "Mostra logo",
            ":": ":",
            logoSetupNote: "Per configurare il logo dell'azienda, contatta {0} o {1}",
            showCompanyLogoInTheEMail: "Mostra il logo dell'azienda nell'e-mail",
            footerImage: "Immagine piè di pagina:",
            footerLink: "Link piè di pagina:"
        },
    };
};

function commonCustomisations() {

    return Object.assign({}, postGroup());

    function postGroup() {

        return assignToObjectKeys(POST_GROUP_COMPANY_IDS, {
            introTitle1: "Benvenuto al barometro dei team della Posta.",
            introText1:
                "Ti ringraziamo della partecipazione. Il sondaggio richiede pochi minuti e sarà condotto e valutato in modo personalizzato. Le tue risposte saranno quindi visibili agli altri. Nella pagina successiva scoprirai come svolgere il barometro del team.",
            introTitle2: "In cosa consiste questo sondaggio",
            introText2:
                "Il barometro del team serve innanzitutto a raccogliere informazioni su come percepisci attualmente la cultura a livello personale, all'interno del team e in generale nel tuo ambiente di lavoro.  La raccolta di queste informazioni serve al tuo team per creare una comprensione comune della cultura attuale, finalizzata alla definizione di misure per l'attuazione dello sviluppo della cultura e della trasformazione. \n\nTi preghiamo di rispondere a queste domande in modo del tutto onesto e spontaneo, non esistono risposte «giuste» o «sbagliate». Oltre a una valutazione quantitativa ti viene anche chiesto di presentare una proposta di miglioramento per il futuro. Ti preghiamo di dedicarvi il tempo necessario. Per noi in quanto team sono fondamentali per comprendere meglio le tue valutazioni ed elaborare misure mirate.\n\nClicca su  «Iniziare»  per avviare il barometro del team! In caso di problemi tecnici durante la compilazione del sondaggio, utilizza la funzione chat che trovi nel barometro del team.",
            gdpr02: "Grazie per aver scelto il Teambarometro. È utilizzato dalla Posta Svizzera in collaborazione con l'azienda Joineer. Al fine di rispettare la presente informativa sulla privacy, desideriamo informarti che, oltre al tuo nome e indirizzo email, raccogliamo le seguenti informazioni:",
            gdpr03: "• Valori quantitativi anonimi per le domande sul Teambarometro.",
            gdpr04: "• Commenti qualitativi o feedforwards facoltativi anonimi o personali.",
            gdpr04pub: "• Commenti qualitativi o feedforwards facoltativi anonimi o personali.",
            gdpr05: "Le risposte possono essere visualizzate dai membri del tuo team, dal responsabile del team e dall'amministratore aziendale.",
            gdpr06: "Ti preghiamo di non condividere dati sensibili o informazioni riservate tramite Joineer. Con il tuo consenso, confermi di tenerne conto.",
            gdpr07: "",
            gdprLinkMessage: "Tutti i dati verranno utilizzati per scopi specifici e condivisi con le seguenti terze parti nell'ambito delle normative sulla protezione dei dati: ",
            gdprLinkTitle: "link",
            gdprLinkUrl: "https://joineer.com/wp-content/uploads/2024/03/Auftrags_Datenverarbeitung-nach-DSGVO.pdf",
            gdpr09: "Grazie per il tuo consenso.",
            gdpr10: "Puoi accedere ai tuoi dati personali in qualsiasi momento tramite il link al Teambarometro. Per qualsiasi domanda, non esitare a contattarci su info@joineer.ch",
            gdpr12: "Joineer e il team di «Cultura del Domani»",
        }, {});
    }
}

function customisations() {
    return {

        // RUAG special texts:
        "651545c98fbb90562f4c065c": {
            gdpr01: "Dichiarazione di consenso",
            gdpr02: "Grazie per aver utilizzato Joineer.\nDesideriamo informarvi che raccogliamo i seguenti dati personali: il vostro nome e il vostro indirizzo e-mail.\nÈ possibile visualizzare i propri dati personali in qualsiasi momento cliccando sul link dell'applicazione.\n\nInoltre, raccogliamo le seguenti informazioni sui risultati:",
            gdpr03: "• Risposte quantitative anonime (valori).",
            gdpr04: "",
            gdpr05: "I risultati sono visibili agli amministratori del reparto e dell'azienda.\nPer la vostra azienda, i risultati saranno mostrati a livello di team se almeno il seguente numero di persone di un team ha partecipato al sondaggio: 5.",
            gdpr06: "Tutti i dati vengono memorizzati su server in Svizzera e non vengono condivisi con terzi. Per ulteriori informazioni, consultare l'Informativa sulla privacy di Joineer (https://joineer.com/datenschutz/).",
            gdpr07: "",
            gdprIgiveMyConsent: "Do il mio consenso",
            gdpr08: "",
            gdpr09: "Per qualsiasi domanda, non esitate a contattarci: info@joineer.ch.",
            gdpr10: "Grazie per la vostra fiducia!",
            gdpr11: "Cordiali saluti",
            gdpr12: "Joineer AG",
            introTitle1: "Benvenuti nel Barometro dei dipendenti RUAG",
            introTitle2: "Siamo qui per voi",
            introText1:
                "Siamo molto felici della vostra partecipazione, perché la vostra opinione conta!",
            introText2:
                "Non esitate a contattarci per qualsiasi domanda all'indirizzo info@joineer.ch. Eccoci qua!",
            startGoMessage: "",
            surveyEndMessage: "Clicca allora su 'Invia'. Se desideri invece cambiare qualcosa, puoi usare il tasto 'Indietro' per tornare alle domande.",
        },

        //Swissinfo special texts:
        "22801SWI182608558": {
            gdpr01: "Dichiarazione di consenso",
            gdpr02: "Grazie per aver utilizzato Joineer. \nDesideriamo informarvi che raccogliamo i seguenti dati personali: nome e indirizzo e-mail. In caso di login tramite cellulare o autenticazione a 2 fattori, anche il numero di cellulare. \nSe la vostra azienda desidera analizzare i risultati in base alle caratteristiche socio-demografiche (ad esempio, età, gruppo di appartenenza), verranno raccolti anche questi dati. \nÈ possibile visualizzare i propri dati personali in qualsiasi momento tramite il link all'app.\n\nRaccogliamo anche le seguenti informazioni sui risultati:",
            gdpr03: "• Risposte quantitative anonime (valori)",
            gdpr04pub: "• Commenti qualitativi personali (feedforward)",
            gdpr05: "I risultati di un team saranno analizzati e visualizzati per la vostra azienda se almeno il seguente numero di persone di un team ha partecipato al sondaggio: 1. In questo caso, le informazioni sui risultati saranno visibili ai membri del vostro team, agli amministratori di divisione e agli amministratori dell'azienda.",
            gdpr06: "Tutti i dati saranno conservati su server in Svizzera e non saranno trasmessi a terzi.",
            gdpr07: "",
            gdprIgiveMyConsent: "Do il mio consenso",
            gdpr08: "",
            gdpr09: "Per qualsiasi domanda, non esiti a contattarci: info@joineer.ch.",
            gdpr10: "Grazie per la vostra fiducia!",
            gdpr11: "Cordiali saluti",
            gdpr12: "Joineer AG"
        },

        //KPT special texts
        "81812kpt446687047": {
            gdpr02: "Si parte... tra poco potrai utilizzare il «Teambarometer».",
            gdpr03: "Nel rispetto delle disposizioni sulla protezione dei dati, vogliamo informarti che, oltre al tuo nome e al tuo indirizzo Email, abbiamo registrato anche le seguenti informazioni: ",
            gdpr04pub:
                "• Risposte quantitative alle domande del «Teambarometer» e commenti qualitativi.",
            gdpr05: "Le tue risposte saranno visualizzate dai membri del tuo team, dal tuo diretto superiore e dai responsabili del progetto.",
            gdpr07: "",
            gdpr12: "KPT & Joineer",
        },

        //Post Kommunikations services special texts
        "95462pks109762813": {
            gdpr05: "I risultati di una squadra vengono visualizzati se almeno 3 persone di una squadra hanno partecipato al sondaggio. Le risposte possono essere visualizzate dai membri del tuo team, dal responsabile del team e dall'amministratore aziendale.",
        },
    };
}
